



































import Vue from 'vue';

export default Vue.extend({
  props: {
    copyText: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: true,
    },
    inputClass: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
    buttonClass: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      copied: false as false|number,
      formId: `copy-text-${Math.random()}`,
    };
  },
  methods: {
    copy(): void {
      navigator.clipboard.writeText(this.copyText);
      if (!this.copied) this.copied = window.setTimeout(() => { this.copied = false; }, 1000);
    },
  },
});
